import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import addOrderForm from "./add-order-form";

import { GenericForm } from "../../components";
import { ReduxProtectedGenericForm } from "../../containers";

class OrderModal extends Component {
	constructor(props) {
		super(props);
		this.onSave = this.onSave.bind(this);

		this.handleChange = this.handleChange.bind(this);
		this.onHide = this.onHide.bind(this);

		// console.log(addOrderForm.validators({}))

		this.state = {
			form: {
				values: addOrderForm.defaultValues(this.props.user, {
					clients: this.props.clients,
				}),
				errors: {},
				error: false,
				loading: false,
			},
			index: -1,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			"items" in nextProps.order &&
			nextProps.order.items.length > 0
		) {
			Object.keys(nextProps.order.items[0]).forEach((key) => {
				console.log(key);
				if (key.startsWith("right")) {
					nextProps.order.pair = true;
					console.log("heeey");
				}
			});
			{
			}
		}

		console.log(nextProps);
		if (
			Object.keys(nextProps.order).length != 0 &&
			nextProps.order != this.props.order
		) {
			this.setState(({ form }) => ({
				index: nextProps.order.index,
				form: {
					...form,
					values: addOrderForm.orderToForm(nextProps.order),
				},
			}));
		}
	}

	handleBlur(group, input, value) {
		// console.log({action: 'blured', group, input, value})
	}

	handleChange(group, input, value) {
		this.setState((state) => {
			let form = { ...state.form };
			form.values = addOrderForm.updateGroups(form.values, {
				group,
				input,
				value,
			});
			return { form };
		});
	}

	onHide() {
		this.setState(
			{
				form: {
					values: addOrderForm.defaultValues(
						this.props.user,
						{ clients: this.props.clients }
					),
					errors: {},
					loading: false,
					error: false,
				},
				index: -1,
			},
			() => this.props.onHide()
		);
	}

	onSave(e) {
		e.preventDefault();
		console.log(this.state.form.values);
		let { errors, error } = GenericForm.validateForm(
			this.state.form.values,
			addOrderForm.validators(this.props.user, {
				values: this.state.form.values,
				clients: this.props.clients,
			})
		);

		if (errors && Object.keys(errors).length > 0)
			this.setState(({ form }) => ({
				form: { ...form, errors, error },
			}));
		else
			this.props
				.onSave(
					addOrderForm.formToOrder(this.state.form.values),
					this.state.index
				)
				.then(() => {
					let index = this.state.index;
					// console.log(index == undefined || index == null || index < 0);
					if (
						index == undefined ||
						index == null ||
						index < 0
					) {
						this.setState(({ form }) => ({
							form: {
								values: addOrderForm.updateGroups(
									form.values,
									{
										group: "norm_group",
										input: "norm",
										value:
											form.values
												.norm_group
												.norm,
									}
								),
								errors: {},
							},
						}));
					}
					// console.log('Modal: ', addOrderForm.formToOrder(this.state.form.values));
				});
	}

	// onSave(){
	// this.props.onSave(addOrderForm.formToOrder(this.state.form), Object.keys(this.props.order).length > 0 ? this.props.order.index : -1 );
	// this.setState((state) => {
	//     return ({form: addOrderForm.updateGroups(state.form, {group: 'norm_group', input: 'norm', value: state.form.norm_group.norm})});
	// });
	// }

	render() {
		let { onHide, show, clients } = this.props;

		let stateForm = this.state.form;

		let form = addOrderForm({ values: stateForm.values, clients });

		return (
			<Modal onHide={this.onHide} id="order-modal" show={show}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter une lentille</Modal.Title>
				</Modal.Header>

				<form noValidate onSubmit={(e) => false}>
					<Modal.Body>
						<ReduxProtectedGenericForm
							wrapper={"div"}
							groups={form.groups}
							// defaultValues={form.defaultValues}

							values={stateForm.values}
							errors={stateForm.errors}
							onChange={this.handleChange}
							onBlur={this.handleBlur}
						/>

						{/* {stateForm.error && <p> <br />Consulter le catalogue <a href="">ICI</a></p>} */}
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="button"
							onClick={this.onSave}
							disabled={this.props.loading}
							variant="primary"
						>
							Ajouter
						</Button>
						<Button
							type="button"
							onClick={this.onHide}
							variant="secondary"
						>
							Fermer
						</Button>
						{/* <Button onClick={ (e) => {this.onSave(); onHide()} } variant="success">Save & Fermer</Button> */}
					</Modal.Footer>
				</form>
			</Modal>
		);
	}
}

export default OrderModal;
