import { ordersActions, overlayActions } from "../_actions";
import { ordersService } from "../_services";
import { history } from "../_helpers";

import config from "../config";
const ordersCreators = {
	fetch: (filters = {}, page = 1, per_page = 10) => (dispatch) => {
		dispatch(ordersActions.fetchRequest());
		return ordersService
			.fetch(filters, page, per_page)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.fetchSuccess(res));
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.fetchFailure(err));
				return Promise.reject(err);
			});
	},
	search: (query) => (dispatch) => {
		dispatch(ordersActions.searchRequest());

		return ordersService
			.checkDuplicate(query)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.searchSuccess());
				Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.searchFailure());
				return Promise.reject(err);
			});
	},
	create: (orders) => (dispatch) => {
		dispatch(ordersActions.createRequest());

		return ordersService
			.create(orders)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.createSuccess(res.data));
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.createFailure(err));
				return Promise.reject(err);
			});
	},
	patch: (order, filters = {}) => (dispatch) => {
		dispatch(ordersActions.patchRequest());

		return ordersService
			.patch(order)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.patchSuccess());
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.patchFailure());
				return Promise.reject(err);
			});
	},
	patchGroup: (orders, filters = {}) => (dispatch) => {
		dispatch(ordersActions.patchGroupRequest());

		return ordersService
			.patchGroup(orders)
			.then((res) => res.data)
			.then((res) => {
				ordersActions.patchGroupSuccess();
				return Promise.resolve(res);
			})
			.catch((err) => {
				ordersActions.patchGroupFailure(err);
				return Promise.reject(err);
			});
	},
	export: ({ orders, file }) => (dispatch) => {
		dispatch(ordersActions.exportRequest());

		return ordersService
			.export({ orders, file })
			.then((res) => res.data)
			.then((res) => {
				res = res.data;
				dispatch(ordersActions.exportFailure());
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.exportFailure());
				return Promise.reject(err);
			});
	},

	exportPdf: (orderId) => (dispatch) => {
		dispatch(ordersActions.exportPdfRequest());

		return ordersService
			.exportPdf(orderId)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.exportAllSuccess());
				return Promise.resolve(res.data);
			})
			.catch((err) => {
				dispatch(ordersActions.exportAllFailure(err));
				return Promise.reject(err);
			});
	},

	exportPdfKeepStatus: (orderId) => (dispatch) => {
		dispatch(ordersActions.exportPdfRequest());

		return ordersService
			.exportPdfKeepStatus(orderId)
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.exportAllSuccess());
				return Promise.resolve(res.data);
			})
			.catch((err) => {
				dispatch(ordersActions.exportAllFailure(err));
				return Promise.reject(err);
			});
	},
	exportAll: () => (dispatch) => {
		dispatch(ordersActions.exportAllSuccess());

		return ordersService
			.exportAll()
			.then((res) => res.data)
			.then((res) => {
				dispatch(ordersActions.exportAllSuccess());
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.exportAllFailure());
				return Promise.reject(err);
			});
	},
	fetchExports: (filters = {}, page = 1) => (dispatch) => {
		dispatch(ordersActions.fetchExportsRequest());
		return ordersService
			.fetchExports(filters, page)
			.then((res) => res.data)
			.then((res) => {
				res.data = res.data.map((e) => ({
					...e,
					path: `${config.uploads}/${e.path}`,
				}));
				dispatch(ordersActions.fetchExportsSuccess(res));
				return Promise.resolve(res);
			})
			.catch((err) => {
				dispatch(ordersActions.fetchExportsFailure(err));
				return Promise.reject(err);
			});
	},
};

export default ordersCreators;
