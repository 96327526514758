import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as Sentry from "@sentry/browser";
import { ReduxProtectedPageTop, ReduxAlert } from "../../containers";

import { OrdersFilters } from "../../components";
import { orderFiltersForm } from "../../components/order-filters/order-filters-form";

import { ordersCreators } from "../../_creators";

import OrdersBarGraph from "../../components/OrdersBarGraph";

// TODO: handle alerts on errors
class Dashboard extends Component {
	constructor(props) {
		super(props);

		// this.validateOrder = this.validateOrder.bind(this);

		this.handleSelectPage = this.handleSelectPage.bind(this);

		this.fetchOrders = this.fetchOrders.bind(this);
		this.fetchPage = this.fetchPage.bind(this);
		this.applyFilters = this.applyFilters.bind(this);

		this.actions = [];

		this.state = {
			...orderFiltersForm.defaultValues(true), // will give filters:{}
			orders: [],
			pagination: {},

			selected_status: "MANIFACTURING",
		};
	}

	componentWillMount() {
		this.fetchOrders(this.state.filters, 1)
			.then((res) => {
				this.setState({
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`'orders-list.js: componentWillMount: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	async fetchOrders(filters = {}, page = 1) {
		let query = Object.keys(filters).reduce((acc, filter) => {
			if (filter.indexOf("date") > -1 || filters[filter] == "")
				return acc;
			return { ...acc, [filter]: filters[filter] };
		}, {});

		query.createdAt = {};

		if (
			filters.starting_date &&
			filters.starting_date instanceof Date
		) {
			filters.starting_date.setHours(0, 0, 0);
			query.createdAt.$gte = filters.starting_date.getTime();
		}

		if (filters.ending_date && filters.ending_date instanceof Date) {
			filters.ending_date.setHours(23, 59, 59);
			query.createdAt.$lte = filters.ending_date.getTime();
		}

		// Fetch SIMPLE orders
		query.type = "SIMPLE";
		const simpleQuery = JSON.stringify(query);
		const simpleOrders = await this.props.fetchOrders(
			{ base64: true, query: btoa(simpleQuery) },
			page
		);

		// Fetch MULTIPLE orders
		query.type = "MULTIPLE";
		const multipleQuery = JSON.stringify(query);
		const multipleOrders = await this.props.fetchOrders(
			{ base64: true, query: btoa(multipleQuery) },
			page
		);

		// Merge results
		return {
			data: [...simpleOrders.data, ...multipleOrders.data],
			pagination: {
				total:
					simpleOrders.pagination.total +
					multipleOrders.pagination.total,
				page: page,
				pages: Math.ceil(
					(simpleOrders.pagination.total +
						multipleOrders.pagination.total) /
						simpleOrders.pagination.limit
				),
			},
		};
	}

	fetchPage(page) {
		this.fetchOrders(this.state.filters, page)
			.then((res) => {
				this.setState({
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`orders-list.js: fetchPage: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	applyFilters(filters) {
		this.fetchOrders(filters, 1)
			.then((res) => {
				this.setState({
					filters,
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`orders-list.js: applyFilters: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	handleSelectPage(selected) {
		this.setState(({ orders }) => {
			orders = [...orders].map((o) => ({ ...o, selected }));
			return { orders };
		});
	}

	render() {
		let { pagination } = this.state;

		pagination = {
			...pagination,
			current: pagination && pagination.page ? pagination.page : 1,
		};

		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={this.actions}
					title="Bienvenue sur Cornelia CRM"
				/>
				<ReduxAlert />

				<OrdersFilters
					onSave={this.applyFilters}
					filters={this.state.filters}
					clients={this.props.clients}
					isDashboard={true}
				/>

				{this.state.orders.length === 0 ? (
					<div
						style={{
							height: "400px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontSize: "1.2em",
							color: "#666",
						}}
					>
						Aucune commande ne correspond à votre
						requête
					</div>
				) : (
					<div
						style={{
							height: "400px",
							margin: "40px",
						}}
					>
						<OrdersBarGraph
							orders={this.state.orders}
						/>
					</div>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients.filter(
		(c) => ["client", "distributor"].indexOf(c.roles) > -1
	),
});

const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (filters = {}, page = 1, per_page = 10000) =>
		ordersCreators.fetch(filters, page, (per_page = 10000))(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
