import React from "react";
import GenericForm from "../_generic-form";
import { checkAbility, checkAuthorization } from "../../_helpers";

const norms = {
	AXELLE38: {
		ref: "AXELLE38",
		description: "",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.3, 8.4, 8.6, 8.7].indexOf(Number(value)) > -1
						? false
						: "Wrong value",
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: "Wrong value",
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["", "8.30", "8.40", "8.60", "8.70"],
				default: "",
				// default: '8.30'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				default: "14.00",
			},
		},
		powers: {
			start: -22.0,
			end: 22.0,
			step: 0.25,
		},
	},
	AXELLE70: {
		ref: "AXELLETOR",
		check: true,
		description: "",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.4, 8.6].indexOf(Number(value)) > -1
						? false
						: "Wrong value",
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: "Wrong value",
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["", "8.40", "8.60"],
				default: "",
				// default: '8.40'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				default: "14.00",
			},
		},
		powers: {
			start: -22.0,
			end: 22.0,
			step: 0.25,
		},
	},
	VERDE: {
		ref: "VERDE",
		description: "",
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.20"],
				default: "14.20",
			},
		},
		powers: {
			start: -25.0,
			end: 25.0,
			step: 0.25,
		},
	},
	GIALLO: {
		ref: "GIALLO",
		description: "",
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.20"],
				default: "14.20",
			},
		},
		powers: {
			start: -25.0,
			end: 25.0,
			step: 0.25,
		},
	},
	PERLA: {
		ref: "PERLA",
		description: "",
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.20"],
				default: "14.20",
			},
		},
		powers: {
			start: -25.0,
			end: 25.0,
			step: 0.25,
		},
	},
	SPICE: {
		ref: "SPICE",
		description: "",
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.20"],
				default: "14.20",
			},
		},
		powers: {
			start: -25.0,
			end: 25.0,
			step: 0.25,
		},
	},
};

const shouldCheck = (norm) => norms[norm].check;

const defaultValues = (user, { values, clients }) => {
	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: "AXELLE38";

	let limits = norms[norm].limits;
	let powers = norms[norm].powers;
	let defaultValues = {
		client_group: {
			userId: checkAbility(user, "orders:create:own")
				? user._id
				: "",
		},
		norm_group: { norm },
		params_group: Object.keys(limits).reduce(
			(acc, cur) => ({ ...acc, [cur]: limits[cur].default }),
			{}
		),
		powers_choose_group: {
			powers_choice: "negative",
		},
		powers_group: {},
	};
	for (var i = powers.start; i <= 0; i += powers.step) {
		defaultValues.powers_group[`p_${i}`] = 0;
	}
	return defaultValues;
};

const updateGroups = (groups, { group, input, value }) => {
	let new_groups = groups;
	new_groups = GenericForm.updateGroups(new_groups, {
		group,
		input,
		value,
	});

	if (group == "norm_group" && input == "norm") {
		Object.keys(norms[value].limits).map((l) => {
			new_groups = GenericForm.updateGroups(new_groups, {
				group: "params_group",
				input: l,
				value: norms[value].limits[l].default,
			});
		});
		// resetting power values to zero
		new_groups.powers_group = {};
		new_groups.powers_choose_group = { powers_choice: "negative" };
		let norm_powers = norms[value].powers;
		for (var i = 0; i >= norm_powers.start; i -= norm_powers.step) {
			new_groups.powers_group[`p_${i}`] = 0;
		}
	} else if (group == "powers_choose_group" && input == "powers_choice") {
		let norm_powers =
			norms[
				groups &&
				groups.norm_group &&
				groups.norm_group.norm &&
				groups.norm_group.norm.length > 0
					? groups.norm_group.norm
					: "AXELLE38"
			].powers;
		new_groups.powers_group = {};
		if (value == "negative") {
			for (
				var i = 0;
				i >= norm_powers.start;
				i -= norm_powers.step
			) {
				new_groups.powers_group[`p_${i}`] = 0;
			}
		} else {
			for (var i = 0; i <= norm_powers.end; i += norm_powers.step) {
				new_groups.powers_group[`p_${i}`] = 0;
			}
		}
	}

	return new_groups;
};

const addOrderStockForm = ({ values, clients = [] }) => {
	return {
		name: "addOrderStockForm",
		id: "addOrderStockForm",
		actions: "",
		method: "",
		groups: {
			client_group: {
				name: "client_group",
				restricted: "orders:create:for-client",
				wrapper: { tag: "div", attrs: { className: "col-12" } },
				inputs: {
					userId: {
						name: "userId",
						label: "Client",
						type: "select",
						values:
							clients.length > 0
								? [
										{
											label:
												"Choisir un client",
											value: "",
										},
										...clients.map(
											(c) => {
												let name = c.partner
													? `${c.name} (${c.partner.name})`
													: c.name;
												return {
													label: name,
													value:
														c._id,
												};
											}
										),
								  ]
								: [],
					},
				},
			},
			norm_group: {
				name: "norm_group",
				wrapper: { tag: "div", attrs: { className: "col-4" } },
				inputs: {
					norm: {
						name: "norm",
						label: "Norme",
						type: "select",
						values: Object.keys(norms).map((n) => ({
							label: n,
							value: n,
						})),
					},
				},
			},
			params_group: {
				name: "params_group",
				wrapper: {
					tag: "div",
					attrs: {
						id: "params-row",
						className: "params-row row col-8",
					},
				},
				inputs: generateParamsFromLimit(
					norms[
						values &&
						values.norm_group &&
						values.norm_group.norm
							? values.norm_group.norm
							: "AXELLE38"
					].limits
				),
			},
			powers_choose_group: {
				name: "powers_choose_group",
				wrapper: {
					tag: "div",
					attrs: {
						className: "col-12",
						id: "powers-choose-group",
					},
				},
				inputs: {
					powers_choice: {
						name: "powers_choice",
						wrapper: {
							tag: "div",
							attrs: {
								className: "form-control-chips",
							},
						},
						type: "chips",
						multiple: false,
						values: ["negative", "positive"].map(
							(p) => ({
								label: p,
								value: p,
								className: `${p}-chip`,
							})
						),
					},
				},
			},
			powers_group: {
				name: "powers_group",
				wrapper: {
					tag: "div",
					attrs: { className: "row params-row powers-row" },
				},
				inputs: generatePowers(
					norms[
						values &&
						values.norm_group &&
						values.norm_group.norm
							? values.norm_group.norm
							: "AXELLE38"
					].powers,
					values &&
						values.powers_choose_group &&
						values.powers_choose_group.powers_choice
						? values.powers_choose_group.powers_choice
						: "negative"
				),
			},
		},
	};
};
const generateParamsFromLimit = (limits) => {
	return Object.keys(limits).reduce(
		(acc, cur) => ({
			...acc,
			[cur]: {
				...limits[cur],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-6" },
				},
			},
		}),
		{}
	);
};
const generatePowers = (norm_powers, sign = "negative") => {
	let powers = {};
	if (sign == "negative") {
		for (var i = 0; i >= norm_powers.start; i -= norm_powers.step) {
			powers[`p_${i}`] = {
				name: `p_${i}`,
				label: `${i.toFixed(2)}`,
				min: 0,
				type: "number",
				step: 1,
				default: 0,
				wrapper: { tag: "div", attrs: { className: "col-3" } },
			};
		}
		return powers;
	} else if (sign == "positive") {
		for (var i = 0; i <= norm_powers.end; i += norm_powers.step) {
			powers[`p_${i}`] = {
				name: `p_${i}`,
				label: `${i.toFixed(2)}`,
				type: "number",
				step: 1,
				default: 0,
				wrapper: {
					tag: "div",
					attrs: { className: "col-3 positive-powers" },
				},
			};
		}
		return powers;
	}
};
const formToOrder = (order) => {
	order = {
		userId: order.client_group.userId,
		norm: order.norm_group.norm,
		items: order.powers_group
			? Object.keys(order.powers_group).reduce((acc, cur) => {
					return [
						...acc,
						{
							r: order.params_group.r,
							d: order.params_group.d,
							p: cur.split("_").pop(),
							qty: order.powers_group[cur],
						},
					];
			  }, [])
			: [],
		powers_choice: order.powers_choose_group.powers_choice,
	};
	// console.log(order);
	return order;
};

const orderToForm = (order) => {
	if (!order.items) return {};
	let res = {
		client_group: { userId: order.userId },
		norm_group: { norm: order.norm },
		params_group: Object.keys(order.items[0]).reduce(
			(acc, p) =>
				p.indexOf("qty") < 0 && p.indexOf("p") < 0
					? { ...acc, [p]: order.items[0][p] }
					: acc,
			{}
		),
		powers_choose_group: { powers_choice: order.powers_choice },
		powers_group: order.items.reduce((acc, item, itemIndex) => {
			return { ...acc, [`p_${item.p}`]: item.qty };
		}, {}),
	};
	console.log(res);
	return res;
};

const validators = (user, { clients, values }) => {
	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: "AXELLE38";
	let errors = norms[norm].errors;

	let validators = {
		client_group: {
			userId: [
				(value) => {
					let possibleValues = [];

					if (!value || (value && value.length) < 1)
						return "Vous devez choisir un client";

					if (checkAbility(user, "orders:create:own"))
						possibleValues.push(user._id);

					if (
						checkAbility(
							user,
							"orders:create:for-client"
						)
					) {
						possibleValues = possibleValues.concat(
							clients.reduce(
								(acc, { _id }) => [...acc, _id],
								[]
							)
						);
					}

					return possibleValues.indexOf(value) > -1
						? false
						: "Vous devez choisir un client";
				},
			],
		},
		norm_group: {
			norm: [
				(value) =>
					value &&
					value.length > 0 &&
					Object.keys(norms).indexOf(value) > -1
						? false
						: "Vous devez choisir une norme",
			],
		},
		params_group: {
			...errors,
		},
	};
	validators.powers_group = Object.keys(values.powers_group).reduce(
		(acc, p) => ({
			...acc,
			[p]: [
				(value) =>
					value == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value >= 1 &&
						value.indexOf(".") == -1 &&
						value % 1 == 0)
						? false
						: true,
			],
		}),
		{}
	);

	return validators;
};

addOrderStockForm.validators = validators;
addOrderStockForm.formToOrder = formToOrder;
addOrderStockForm.orderToForm = orderToForm;
addOrderStockForm.defaultValues = defaultValues;
addOrderStockForm.updateGroups = updateGroups;
addOrderStockForm.norms = norms;
addOrderStockForm.shouldCheck = shouldCheck;

export default addOrderStockForm;
