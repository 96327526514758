import React, { Fragment } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { date } from "../../utils";

import "./generic-input.css";

const GenericInput = (props) => {
	let {
		type,
		wrapper,
		errors,
		onChange,
		onBlur,
		label,
		className,
		errorBorder,
		name,
		value,
	} = props;

	let InputWrapper = wrapper && wrapper.tag ? wrapper.tag : "p";
	let wrapperAttrs = wrapper && wrapper.attrs ? wrapper.attrs : {};

	className = className ? className : "form-control";

	if (name.includes("left") || name.includes("right")) {
		className = "form-control bold-form";
	}

	errors =
		errors && Object.keys(errors).length > 0
			? Object.keys(errors).map((e) => errors[e])
			: null;

	className = errors ? className + " is-invalid" : className;

	switch (type) {
		case "text":
		case "email":
		case "password":
		case "number":
			return (
				<InputWrapper {...wrapperAttrs}>
					{label && <label>{label}</label>}
					<TextInput className={className} {...props} />
					{errors && <ErrorsWrapper errors={errors} />}
				</InputWrapper>
			);
		case "select":
			return (
				<InputWrapper {...wrapperAttrs}>
					{label && <label>{label}</label>}
					<SelectInput className={className} {...props} />
					{errors && <ErrorsWrapper errors={errors} />}
				</InputWrapper>
			);
		case "chips":
			if (props.multiple) {
				return (
					<InputWrapper {...wrapperAttrs}>
						{label && <label>{label}</label>}
						<ChipsSelectInputMultiple
							className={className}
							{...props}
						/>
						{errors && (
							<ErrorsWrapper errors={errors} />
						)}
					</InputWrapper>
				);
			} else {
				return (
					<InputWrapper {...wrapperAttrs}>
						{label && <label>{label}</label>}
						<ChipsSelectInput
							className={className}
							{...props}
						/>
						{errors && (
							<ErrorsWrapper errors={errors} />
						)}
					</InputWrapper>
				);
			}
		case "date":
			return (
				<InputWrapper {...wrapperAttrs}>
					{label && <label>{label}</label>}
					<DateInput className={className} {...props} />
					{errors && <ErrorsWrapper errors={errors} />}
				</InputWrapper>
			);
		case "button":
			return (
				<InputWrapper {...wrapperAttrs}>
					{label && <label>{label}</label>}
					<GenericButton className={className} {...props} />
					{errors && <ErrorsWrapper errors={errors} />}
				</InputWrapper>
			);
		case "checkbox":
			return (
				<InputWrapper {...wrapperAttrs}>
					<CheckboxInput className={className} {...props} />
					{label && (
						<label
							htmlFor={props.id}
							className="custom-control-label"
						>
							{label}
						</label>
					)}
					{errors && <ErrorsWrapper errors={errors} />}
				</InputWrapper>
			);
		default:
			return <p>Not a valid</p>;
	}
};

const isFloat = (value) => {
	// Use parseFloat to attempt conversion
	const floatValue = parseFloat(value);

	// Check if it's a valid number and not NaN (which is not a valid number)
	// Also, check if it's a finite number (to exclude Infinity and -Infinity)
	return !isNaN(floatValue) && isFinite(floatValue);
};
const TextInput = ({
	value,
	onChange,
	onBlur,
	disabled,
	placeholder,
	name,
	type,
	className,
	...props
}) => {
	if (
		name &&
		(name.endsWith("cyl") || name.endsWith("sph") || name.endsWith("p"))
	) {
		return (
			<>
				<input
					{...props}
					className={className || ""}
					name={name}
					type="text" //{type}
					value={value}
					disabled={disabled}
					placeholder={placeholder || ""}
					onChange={(e) => {
						e.target.className = e.target.className.replaceAll(
							"is-invalid-format",
							""
						);

						const numberAlert = document.getElementsByClassName(
							"number-alert " + name
						)[0];

						numberAlert.style.display = "none";

						return onChange
							? onChange(e.target.value)
							: null;
					}}
					onBlur={(e) =>
						onBlur ? onBlur(e.target.value) : null
					}
				/>
				<p
					className={"sign-alert " + name}
					style={{ display: "none" }}
				>
					Un symbole + ou - doit être entrer avant les
					chiffres
				</p>
				<p
					className={"number-alert " + name}
					style={{ display: "none" }}
				>
					Erreur le champ doit contenir un nombre !
				</p>
			</>
		);
	}

	return (
		<input
			{...props}
			className={className || ""}
			name={name}
			type={type}
			value={value}
			disabled={disabled}
			placeholder={placeholder || ""}
			onChange={(e) => (onChange ? onChange(e.target.value) : null)}
			onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
		/>
	);
};

const SelectInput = ({ value, onChange, onBlur, disabled, ...props }) => {
	let values = !(props.values[0] instanceof Object)
		? props.values.map((v) => ({ value: v, label: v }))
		: props.values;

	return (
		<select
			{...props}
			defaultValue={value}
			value={value}
			onChange={(e) => (onChange ? onChange(e.target.value) : null)}
			onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
			disabled={disabled}
		>
			{values.map((v, index) => (
				<option key={index} value={v.value}>
					{v.label}
				</option>
			))}
		</select>
	);
};

const ChipsSelectInput = ({ value, onChange, onBlur, disabled, ...props }) => {
	let values = !(props.values[0] instanceof Object)
		? props.values.map((v) => ({ value: v, label: v }))
		: props.values;

	return (
		<Fragment>
			{values.map((v, index) => (
				<span
					key={index}
					onClick={(e) => onChange(v.value)}
					className={`form-control-chip ${
						v.className ? v.className : ""
					}${value == v.value ? " active" : ""}`}
				>
					{v.label}
				</span>
			))}
		</Fragment>
	);
};

const ChipsSelectInputMultiple = ({
	value,
	onChange,
	onBlur,
	disabled,
	...props
}) => {
	value = value && value instanceof Array && value.length > 0 ? value : [];

	let values = !(props.values[0] instanceof Object)
		? props.values.map((v) => ({ value: v, label: v }))
		: props.values;

	const handleChange = (clicked) => {
		if (value.indexOf(clicked) > -1) {
			// remove it
			onChange(value.filter((v) => v != clicked));
		} else {
			onChange([...value, clicked]);
		}
	};

	return (
		<Fragment>
			{values.map((v, index) => (
				<span
					key={index}
					onClick={(e) => handleChange(v.value)}
					className={`form-control-chip ${
						value.indexOf(v.value) > -1 ? "active" : ""
					}`}
				>
					{v.label}
				</span>
			))}
		</Fragment>
	);
};
const GenericButton = ({ text, ...props }) => {
	return <button {...props}>{text}</button>;
};

const CheckboxInput = ({ value, onChange, ...props }) => {
	return (
		<input
			type="checkbox"
			{...props}
			checked={value}
			onChange={(e) => onChange(e.target.checked)}
		/>
	);
};
const DateInput = ({ value, onChange }) => {
	// console.log({value, onChange});
	return (
		<DatePicker
			dateFormat="dd-MM-yyyy"
			className="form-control"
			selected={value}
			onChange={(v) => onChange(v)}
		/>
	);
};

const ErrorsWrapper = ({ errors }) => {
	return (
		<Fragment>
			{errors.map(
				(e, index) =>
					typeof e == "string" &&
					e.length > 0 && (
						<small
							key={index}
							className="form-text text-muted"
						>
							{e}
						</small>
					)
			)}
		</Fragment>
	);
};

export default GenericInput;
