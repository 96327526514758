import { modulos } from "../../utils";

export const norms = {
	AXELLE: {
		ref: "AXELLE",
		chipcolor: "axelle70 top",
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.4, 8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "8.40", "8.60"],
				default: "",
				// default: '8.40'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.00"],
				default: "14.00",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	"AXELLE PRS": {
		ref: "AXELLE PRS",
		chipcolor: "axelle70prs top",
		description: "",
		check: true,
		blue: true,
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 9.2 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 0 &&
					value <= 15.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				// default: '14.00',
				placeholder: "14.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	TORIQUE: {
		ref: "TORIQUE",
		chipcolor: "axelletorique70 top",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 9.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				values: ["", "8.30", "8.40", "8.50", "8.60", "8.70"],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},

	"TORIQUE PRS": {
		ref: "TORIQUE PRS",
		chipcolor: "axelletorique70prs top",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8 &&
					value <= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				values: [
					"",
					"8.00",
					"8.10",
					"8.20",
					"8.30",
					"8.40",
					"8.50",
					"8.60",
					"8.70",
					"8.80",
					"8.90",
					"9.00",
				],

				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},

	DK18: {
		ref: "DK18",
		chipcolor: "dkkera top",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			/*
            e: {
                name: "e",
                label: "Edge Lift", 
                wrapper: {tag: "div", attrs: {className: "col-sm-4 hidden"}},
                type: "number",
                default: '',
                placeholder: '0.00',
                step: 0.5
            },
            */
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK36: {
		ref: "DK36",
		chipcolor: "dkkera top",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "8.00",
				// default: '8.00',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK92: {
		ref: "DK92",
		chipcolor: "dkkera top",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK100: {
		ref: "DK100",
		chipcolor: "dkkera top",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK125: {
		ref: "DK125",
		chipcolor: "dkkera top",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	KERA18: {
		ref: "KERA18",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA36: {
		ref: "KERA36",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA92: {
		ref: "KERA92",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "5.00",
				// default: '5.00',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA100: {
		ref: "KERA100",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA125: {
		ref: "KERA125",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// LENTILLES COULEURS

	GIALLO: {
		ref: "GIALLO",
		description: "",
		chipcolor: "giallo disabled top",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: /*{
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},*/ {}
	},

	PERLA: {
		ref: "PERLA",
		description: "",
		chipcolor: "perla top",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICE: {
		ref: "SPICE",
		description: "",
		chipcolor: "spice top",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {r: {
			name: "r",
			label: "Rayon",
			type: "select",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			values: ["8.60"],
			default: "8.60",
		},
		d: {
			name: "d",
			label: "Diametre",
			type: "select",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			values: ["14.20"],
			default: "14.20",
		},
		p: {
			name: "p",
			label: "Puissance",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			type: "number",
			default: "",
			// default: '0.00',
			placeholder: "0.00",
			step: 0.25,
		},
		qty: {
			name: "qty",
			min: "0",
			label: "Quantité",

			wrapper: { tag: "div", attrs: { className: "col-3" } },
			type: "number",
			default: "1",
		}},
	},

	VERDE: {
		ref: "VERDE",
		description: "",
		chipcolor: "verde top",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	GIALLOPLAN: {
		ref: "GIALLOPLAN",
		description: "",
		chipcolor: "giallo disabled",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: /*{
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},*/ {}
	},

	PERLAPLAN: {
		ref: "PERLAPLAN",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICEPLAN: {
		ref: "SPICEPLAN",
		description: "",
		chipcolor: "spice",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
			name: "r",
			label: "Rayon",
			type: "select",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			values: ["8.60"],
			default: "8.60",
		},
		d: {
			name: "d",
			label: "Diametre",
			type: "select",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			values: ["14.20"],
			default: "14.20",
		},
		p: {
			name: "p",
			label: "Puissance",
			wrapper: { tag: "div", attrs: { className: "col-3" } },
			type: "select",
			values: ["0.00"],
			default: "0.00",
		},
		qty: {
			name: "qty",
			min: "0",
			label: "Quantité",

			wrapper: { tag: "div", attrs: { className: "col-3" } },
			type: "number",
			default: "1",
		}},
	},

	VERDEPLAN: {
		ref: "VERDEPLAN",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	// Done by Yacine
	SCLERALE: {
		ref: "SCLERALE",
		chipcolor: "sclerale top",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-3" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-3" },
				},
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-2" } },
				values: ["", "15.50", "16.50"],
				default: "",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SCLERALE TORIQUE": {
		ref: "SCLERALE TORIQUE",
		chipcolor: "sclerale top",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			tor: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[50, 100, 150, 200].indexOf(Number(value)) > -1
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["", "15.50", "16.50"],
				default: "",
			},
			tor: {
				name: "tor",
				label: "Toricité",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["", "50", "100", "150", "200"],
				default: "",
				// default: '8.30'
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2 mt-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2 mt-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SM-SCL": {
		ref: "MINI SCLERALE",
		chipcolor: "sclerale top",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.6 &&
					value >= 6 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 15.5 &&
					value >= 14.5 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 30 &&
					value >= -30 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "6.00",
				step: 0.1,
			},

			d: {
				name: "d",
				label: "Diamètre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "14.50",
				step: 0.1,
			},

			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// LENTILLE TORIQUE RIGIDE
	"T.IN42": {
		ref: "TORIQUE INTERNE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT42": {
		ref: "TORIQUE EXTERNE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],

			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},

			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B42: {
		ref: "BITORIQUE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.IN100": {
		ref: "TORIQUE INTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT100": {
		ref: "TORIQUE EXTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],

			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},

			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B100: {
		ref: "BITORIQUE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.IN125": {
		ref: "TORIQUE INTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT125": {
		ref: "TORIQUE EXTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			/* r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			], */
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			/*
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			*/
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B125: {
		ref: "BITORIQUE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// COULEURS TORIQUE

	"GIALLO TORIQUE": {
		ref: "GIALLO TORIQUE",
		description: "",
		chipcolor: "giallo disabled top",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: /*{
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},*/ {}
	},

	"PERLA TORIQUE": {
		ref: "PERLA TORIQUE",
		description: "",
		chipcolor: "perla top",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SPICE TORIQUE": {
		ref: "SPICE TORIQUE",
		description: "",
		chipcolor: "spice top",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {r: {
			name: "r",
			label: "Rayon",
			type: "select",
			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			values: ["8.60"],
			default: "8.60",
		},
		d: {
			name: "d",
			label: "Diametre",
			type: "select",
			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			values: ["14.20"],
			default: "14.20",
		},
		sph: {
			name: "sph",
			label: "Sphère",
			type: "number",
			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			default: "",
			// default: '0.00',
			placeholder: "0.00",
			step: 0.25,
		},
		cyl: {
			name: "cyl",
			label: "Cyl",
			type: "number",
			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			default: "",
			// default: '-0.50',
			placeholder: "-0.25",
			step: 0.25,
		},
		axe: {
			name: "axe",
			label: "Axe",
			type: "number",
			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			default: "",
			// default: '0',
			placeholder: "0",
			step: 5,
		},
		qty: {
			name: "qty",
			min: "0",
			label: "Quantité",

			wrapper: {
				tag: "div",
				attrs: { className: "col-sm-4" },
			},
			type: "number",
			default: "1",
		}},
	},
	"VERDE TORIQUE": {
		ref: "VERDE TORIQUE",
		description: "",
		chipcolor: "verde top",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
};