import React from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const OrdersBarGraph = ({ orders }) => {
	orders = orders.map((order) => ({
		...order,
		norm:
			order.norm === "AXELLE 70" || order.norm === "AXELLE70"
				? "AXELLE"
				: order.norm,
	}));

	console.log(orders);

	const usedNorms = [...new Set(orders.map((order) => order.norm))];

	// Sum left_qty per norm
	const normQuantities = usedNorms.reduce((acc, norm) => {
		acc[norm] = orders
			.filter((order) => order.norm === norm)
			.reduce((sum, order) => {
				if (order.type === "MULTIPLE") {
					return (
						sum +
						order.items.reduce(
							(itemSum, item) =>
								itemSum + (item.qty || 0),
							0
						)
					);
				}
				return sum + (order.items[0].left_qty || 0);
			}, 0);
		return acc;
	}, {});

	const data = {
		labels: usedNorms,
		datasets: [
			{
				label: "Quantité totale",
				data: usedNorms.map((norm) => normQuantities[norm]),
				backgroundColor: "rgba(54, 162, 235, 0.5)",
				borderColor: "rgb(54, 162, 235)",
				borderWidth: 1,
			},
		],
	};

	const totalOrders = Object.values(normQuantities).reduce(
		(sum, count) => sum + count,
		0
	);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: { position: "top" },
			title: {
				display: true,
				text: `${totalOrders} commandes effectuées`,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: { stepSize: 1 },
			},
		},
	};

	return <Bar data={data} options={options} />;
};

export default OrdersBarGraph;
