import React from "react";
import GenericForm from "../_generic-form";
import { checkAbility, checkAuthorization } from "../../_helpers";

import { norms } from "./norms";

// const categories = ['LENTILLE SOUPLE', 'LENTILLE TORIQUE SOUPLE', 'LENTILLE RIGIDE', 'LENTILLE TORIQUE RIGIDE', 'LENTILLE SCLERALE', 'LENTILLE COULEUR', 'LENTILLE COULEUR TORIQUE']
const categories = [
	"LENTILLE SOUPLE",
	"LENTILLE TORIQUE SOUPLE",
	"LENTILLE RIGIDE",
	"LENTILLE TORIQUE RIGIDE",
	"LENTILLE COULEUR",
	"LENTILLE SCLERALE",
	"LENTILLE COULEUR TORIQUE",
];

const addOrderForm = ({ values, clients = [] }) => {
	//let norm = norms[values && values.norm_group && values.norm_group.norm ? values.norm_group.norm : 'AXELLE38'];

	if (!values["blue_group"])
		values.blue_group = { blue: false, pair: false };

	let norm =
		norms[
			values && values.norm_group && values.norm_group.norm
				? values.norm_group.norm
				: {}
		];

	const category = values.category_group
		? values.category_group.norm
		: "LENTILLE SOUPLE";
	let form = {
		name: "addOrderForm",
		id: "addOrderForm",
		actions: "",
		method: "",
		groups: {
			client_group: {
				name: "client_group",
				restricted: "orders:create:for-client",
				inputs: {
					userId: {
						name: "userId",
						label: "Client",
						type: "select",
						values:
							clients.length > 0
								? [
										{
											label:
												"Choisir un client",
											value: "",
										},
										...clients.map(
											(c) => {
												let name = c.partner
													? `${c.name} (${c.partner.name})`
													: c.name;
												return {
													label: name,
													value:
														c._id,
												};
											}
										),
								  ]
								: [],
					},
				},
			},

			category_group: {
				name: "category_group",
				wrapper: { tag: "div" },
				inputs: {
					category: {
						name: "norm",
						wrapper: {
							tag: "div",
							attrs: {
								className: "form-control-cat",
							},
						},
						type: "chips",
						multiple: false,
						values: categories.map((n) =>
							n === "LENTILLE TORIQUE RIGIDE" ||
							n === "LENTILLE TORIQUE SOUPLE" ||
							n === "LENTILLE COULEUR TORIQUE"
								? {
										label: n,
										value: n,
										className:
											"cat-chip large-cat",
								  }
								: {
										label: n,
										value: n,
										className:
											"cat-chip",
								  }
						),
					},
				},
			},

			norm_group: {
				name: "norm_group",
				wrapper: { tag: "div" },
				inputs: {
					norm: {
						name: "norm",
						wrapper: {
							tag: "div",
							attrs: {
								className: "form-control-chips",
							},
						},
						type: "chips",
						multiple: false,
						values: Object.keys(norms)
							.filter(
								(n) =>
									norms[n].category ===
									category
							)
							.map((n) => ({
								label: norms[n].ref,
								value: n,
								className: norms[n].chipcolor,
							})),
					},
				},
			},

			left_group: {
				name: "left_group",
				wrapper: {
					tag: "div",
					attrs: { className: "row params-row" },
				},
				inputs: norm
					? generateParamsFromLimit("left", norm.limits)
					: "",
			},
			right_group: {
				name: "right_group",
				wrapper: {
					tag: "div",
					attrs: { className: "row params-row" },
				},
				inputs: norm
					? values.blue_group.pair
						? generateParamsFromLimit(
								"right",
								norm.limits
						  )
						: ""
					: "",
			},
		},
	};

	if (norm) {
		form.groups.blue_group = {
			name: "blue_group",
			wrapper: {
				tag: "div",
				attrs: {
					className: "text-right pt-2 checkboxes",
				},
			},

			inputs: {
				pair: {
					name: "pair",
					wrapper: {
						tag: "div",
						attrs: {
							className:
								"mr-2  custom-control custom-checkbox",
						},
					},
					type: "checkbox",
					label: "Paire",
					className: "custom-control-input ",
					id: "pair-input",
				},
			},
		};
		if (norm.blue) {
			form.groups.blue_group = {
				name: "blue_group",
				wrapper: {
					tag: "div",
					attrs: {
						className: "text-right pt-2 checkboxes",
					},
				},

				inputs: {
					blue: {
						name: "blue",
						wrapper: {
							tag: "div",
							attrs: {
								className:
									"mr-2  custom-control custom-checkbox",
							},
						},
						type: "checkbox",
						label: "Bleutée",
						className: "custom-control-input ",
						id: "blue-input",
					},
					pair: {
						name: "pair",
						wrapper: {
							tag: "div",
							attrs: {
								className:
									"mr-2  custom-control custom-checkbox",
							},
						},
						type: "checkbox",
						label: "Paire",
						className: "custom-control-input ",
						id: "pair-input",
					},
				},
			};
		}
	}
	return form;
};

const generateParamsFromLimit = (side, limits, disabled = false) => {
	return Object.keys(limits).reduce(
		(acc, cur) => ({
			...acc,
			[`${side}_${cur}`]: {
				...limits[cur],
				name: `${side}_${cur}`,
				disabled,
			},
		}),
		{}
	);
};

const shouldCheck = (norm) => norms[norm].check;

const defaultValues = (user, { values, clients }) => {
	// let norm = Object.keys(norms)[0];

	//let default_norm_category = Object.keys(norms).filter(n => norms[n].category === category)[0]

	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: {};

	let limits = norms[norm] ? norms[norm].limits : "";
	let defaults = {
		client_group: {
			userId: checkAbility(user, "orders:create:own")
				? user._id
				: "",
		},
		norm_group: { norm },
		left_group: Object.keys(limits).reduce(
			(acc, cur) => ({
				...acc,
				[`left_${cur}`]: limits[cur].default,
			}),
			{}
		),
		left_group: Object.keys(limits).reduce(
			(acc, cur) => ({
				...acc,
				[`right${cur}`]: limits[cur].default,
			}),
			{}
		),
		// right_group: {
		//     ...Object.keys(limits).reduce((acc, cur) => ({...acc, [`right_${cur}`]: limits[cur].default }), {}),
		//     right_qty: ["TORIQUE", "TORIQUE38"].indexOf(norm.ref) > -1 ? '0' : '1'
		// }
	};
	if (norm[norm]) {
		if (norms[norm].blue) {
			defaults.blue_group = {
				blue: false,
			};
		}
	}

	return defaults;
};

const validators = (user, { clients, values }) => {
	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: "AXELLE38";
	let errors = norms[norm].errors;

	const validateDecimalFormat = (value, fieldName) => {
		if (fieldName === "qty" || fieldName === "axe" || !value)
			return false;

		// Remove leading + or - sign for pattern testing
		const valueWithoutSign = value.replace(/^[+-]/, "");

		const pattern = /^\d{1,2}\.\d{2}$/;
		if (!pattern.test(valueWithoutSign)) {
			return "Le champ doit contenir 2 chiffre apres la virgule !";
		}
		return false;
	};

	const validateSignFormat = (value, fieldName) => {
		if (!["cyl", "sph", "p"].includes(fieldName)) return false;

		if (value === "0.00" || value === "0" || value === "00")
			return false;

		const signPattern = /^[+-]/;
		if (!signPattern.test(value)) {
			return "Un signe + ou - est requis";
		}
		return false;
	};

	const combineValidations = (baseValidations, fieldName) => {
		let combined = [...baseValidations];

		combined.push((value) => validateDecimalFormat(value, fieldName));

		if (
			fieldName.endsWith("cyl") ||
			fieldName.endsWith("sph") ||
			fieldName.endsWith("p")
		) {
			combined.push((value) =>
				validateSignFormat(value, fieldName)
			);
		}

		return combined;
	};

	let validators = {
		client_group: {
			userId: [
				(value) => {
					let possibleValues = [];
					if (!value || (value && value.length) < 1)
						return "Vous devez choisir un client";
					if (checkAbility(user, "orders:create:own"))
						possibleValues.push(user._id);
					if (
						checkAbility(
							user,
							"orders:create:for-client"
						)
					) {
						possibleValues = possibleValues.concat(
							clients.reduce(
								(acc, { _id }) => [...acc, _id],
								[]
							)
						);
					}
					return possibleValues.indexOf(value) > -1
						? false
						: "Vous devez choisir un client";
				},
			],
		},
		norm_group: {
			norm: [
				(value) =>
					value &&
					value.length > 0 &&
					Object.keys(norms).indexOf(value) > -1
						? false
						: "Vous devez choisir une norme",
			],
		},
		left_group: Object.keys(errors).reduce((acc, cur) => {
			if (!errors[cur] || cur === "qty") return acc;
			return {
				...acc,
				["left_" + cur]: combineValidations(errors[cur], cur),
			};
		}, {}),
	};

	if (
		values &&
		values.right_group &&
		values.right_group.right_qty != 0 &&
		values.blue_group &&
		values.blue_group.pair
	) {
		validators.right_group = Object.keys(errors).reduce((acc, cur) => {
			if (!errors[cur] || cur === "qty") return acc;
			return {
				...acc,
				["right_" + cur]: combineValidations(errors[cur], cur),
			};
		}, {});
	}

	return validators;
};

const updateGroups = (groups, { group, input, value }) => {
	let new_groups = groups;
	if (group == "norm_group" && input == "norm") {
		value = value && value.length > 0 ? value : "AXELLE38";

		new_groups = GenericForm.updateGroups(new_groups, {
			group,
			input,
			value,
		});

		["left", "right"].map((g) => {
			Object.keys(norms[value].limits).map((l) => {
				let lg = `${g}_${l}`;
				let new_value =
					lg == "right_qty"
						? "1"
						: norms[value].limits[l].default; // setting right qty to 0
				// let new_value = lg == 'right_qty' && ["TORIQUE", "TORIQUE38"].indexOf(norms[value].ref) > -1 ? 0 : norms[value].limits[l].default; // setting right qty to 0
				// let new_value = norms[value].limits[l].default;
				new_groups = GenericForm.updateGroups(new_groups, {
					group: `${g}_group`,
					input: lg,
					value: new_value,
				});
			});
		});

		if (norms[value].blue) {
			new_groups.blue_group = { blue: false };
		}
		new_groups.blue_group = { pair: false };
	} else {
		// if(isNaN(value)){

		new_groups = GenericForm.updateGroups(new_groups, {
			group,
			input,
			value,
		});

		if (
			group === "left_group" &&
			new_groups.norm_group.norm === "SCLERALE TORIQUE"
		) {
			if (new_groups.left_group.left_d === "15.50") {
				norms["SCLERALE TORIQUE"].limits.tor.values = [
					"",
					"50",
					"100",
				];
			} else {
				norms["SCLERALE TORIQUE"].limits.tor.values = [
					"",
					"50",
					"100",
					"150",
					"200",
				];
			}
		}

		if (
			group === "left_group" ||
			(group === "right_group" &&
				new_groups.norm_group.norm === "TORIQUE PRS")
		) {
			if (
				["8.30", "8.40", "8.50", "8.60", "8.70"].includes(
					new_groups.left_group.left_r
				)
			) {
				norms["TORIQUE PRS"].limits.d.values = [
					"",
					"13.50",
					"14.50",
				];

				norms["TORIQUE PRS"].errors.d = [
					(value) =>
						value &&
						value.length > 0 &&
						!isNaN(value) &&
						[14.5, 13.5].indexOf(Number(value)) > -1
							? false
							: true,
				];
			} else {
				norms["TORIQUE PRS"].limits.d.values = [
					"",
					"13.50",
					"14.00",
					"14.50",
				];
				norms["TORIQUE PRS"].errors.d = [
					(value) =>
						value &&
						value.length > 0 &&
						!isNaN(value) &&
						[14.0, 14.5, 13.5].indexOf(Number(value)) >
							-1
							? false
							: true,
				];
				norms["TORIQUE PRS"].limits.d.default = "14.00";
			}
		}

		if (group == "category_group") {
			let cat = new_groups.category_group
				? new_groups.category_group.norm
				: "LENTILLE SOUPLE";
			let default_category = "";
			new_groups.norm_group.norm = default_category;
			new_groups.left_group = {};
		}

		// }else{
		//     new_groups = GenericForm.updateGroups(new_groups, {group, input, value: Number.parseFloat(value).toFixed(2)});
		// }
	}

	// Show the default Limits of the selected category Done By Yacine

	return new_groups;
};

// const updateGroups = (groups, {group, input, value}) => {
//     let new_groups = groups;
//     if(group == 'norm_group' && input == 'norm'){
//         value = value && value.length > 0 ? value : 'AXELLE38';
//         new_groups = GenericForm.updateGroups(new_groups, {group, input, value});

//         ['left', 'right'].map( (g) => {
//             Object.keys(norms[value].limits).map( (l) => {
//                 let lg = `${g}_${l}`;
//                 new_groups = GenericForm.updateGroups(new_groups, {group: `${g}_group`, input: lg, value: norms[value].limits[l].default})
//             });
//         });

//     }else{
//         new_groups = GenericForm.updateGroups(new_groups, {group, input, value});
//     }

//     return new_groups
// }

const formToOrder = (order) => {
	let items = {};
	let limits = norms[order.norm_group.norm].limits;

	Object.keys(limits).reduce((acc, limit) => {
		let sides = order.blue_group.pair ? ["left", "right"] : ["left"];
		sides.map((side) => {
			items[`${side}_${limit}`] =
				order[`${side}_group`][`${side}_${limit}`];
		});
	}, {});

	let res = {
		userId: order.client_group.userId,
		norm: order.norm_group.norm,
		items: [items],
	};

	if (norms[res.norm].blue) res.blue = order.blue_group.blue;

	return res;
};

const orderToForm = (order) => {
	let res = {};
	if (!order.items) return {};

	if (order.items[0] === undefined) return {};

	order.items = order.items[0];

	res = {
		client_group: { userId: order.userId },
		norm_group: { norm: order.norm },
		left_group: Object.keys(order.items).reduce(
			(acc, cur) =>
				cur.indexOf("left") > -1
					? { ...acc, [cur]: order.items[cur] }
					: acc,
			{}
		),
		right_group: order.pair
			? Object.keys(order.items).reduce(
					(acc, cur) =>
						cur.indexOf("right") > -1
							? { ...acc, [cur]: order.items[cur] }
							: acc,
					{}
			  )
			: "",
	};

	if (norms[order.norm].blue) {
		res.blue_group = { blue: order.blue };
	}
	if (order.pair) {
		res.blue_group = { pair: true };
	}

	return res;
};

const extractors = (values) => {
	return {
		left_group: {
			value: (group) => group,
		},
	};
};

addOrderForm.formToOrder = formToOrder;
addOrderForm.orderToForm = orderToForm;
addOrderForm.defaultValues = defaultValues;
addOrderForm.validators = validators;
addOrderForm.updateGroups = updateGroups;
addOrderForm.norms = norms;
addOrderForm.extractors = extractors;
addOrderForm.shouldCheck = shouldCheck;

export default addOrderForm;
