import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as Sentry from "@sentry/browser";
import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
	ReduxAlert,
} from "../../containers";

import {
	Pagination,
	OrdersFilters,
	GenericForm,
	PatchRow,
} from "../../components";
import { orderFiltersForm } from "../../components/order-filters/order-filters-form";
import { history } from "../../_helpers";
import { orderListTable } from "./order-list-table";

import { ordersCreators } from "../../_creators";
import { arrayToObject } from "../../utils";
import { Order } from "../../models";

import config from "../../config";

import "./order-list.css";
// TODO: handle alerts on errors
class OrderListPage extends Component {
	constructor(props) {
		super(props);

		// this.validateOrder = this.validateOrder.bind(this);

		this.handleSelectLine = this.handleSelectLine.bind(this);
		this.handleSelectPage = this.handleSelectPage.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);

		this.fetchOrders = this.fetchOrders.bind(this);
		this.fetchPage = this.fetchPage.bind(this);
		this.applyFilters = this.applyFilters.bind(this);

		this.rejectOrder = this.rejectOrder.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
		this.patchGroup = this.patchGroup.bind(this);

		this.handleReturnOrder = this.handleReturnOrder.bind(this);

		this.actions = [
			{
				text: "Ajouter",
				onClick: (e) => history.push("/order/add"),
				restricted: "orders:create",
				color: "btn-primary",
			},
			{
				text: "Exporter",
				onClick: (e) => history.push("/order/export"),
				restricted: "orders:export:create",
				color: "btn-info",
			},
		];

		this.state = {
			...orderFiltersForm.defaultValues(), // will give filters:{}
			orders: [],
			pagination: {},

			selected_status: "VALIDATED",
		};
	}

	componentWillMount() {
		console.log(this.state.filters);
		this.fetchOrders(this.state.filters, 1)
			.then((res) => {
				this.setState({
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`'orders-list.js: componentWillMount: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	async fetchOrders(filters = {}, page = 1) {
		let query = Object.keys(filters).reduce((acc, filter) => {
			if (filter.indexOf("date") > -1 || filters[filter] == "")
				return acc;
			return { ...acc, [filter]: filters[filter] };
		}, {});
		query.type = "SIMPLE";
		query.createdAt = {};

		if (
			filters.starting_date &&
			filters.starting_date instanceof Date
		) {
			filters.starting_date.setHours(0, 0, 0);
			query.createdAt.$gte = filters.starting_date.getTime();
		}

		if (filters.ending_date && filters.ending_date instanceof Date) {
			// filters.ending_date.setDate(filters.ending_date.getDate() );
			filters.ending_date.setHours(23, 59, 59);

			query.createdAt.$lte = filters.ending_date.getTime();
		}

		query = JSON.stringify(query);
		console.log("OrderListPage: fetching query: ", query);
		return this.props.fetchOrders(
			{ base64: true, query: btoa(query) },
			page
		);
	}

	fetchPage(page) {
		this.fetchOrders(this.state.filters, page)
			.then((res) => {
				this.setState({
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`orders-list.js: fetchPage: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	applyFilters(filters) {
		this.fetchOrders(filters, 1)
			.then((res) => {
				this.setState({
					filters,
					orders: res.data.map((o) => ({
						...o,
						selected: false,
					})),
					pagination: res.pagination,
				});
			})
			.catch((error) => {
				Sentry.captureMessage(
					`orders-list.js: applyFilters: error ', ${JSON.stringify(
						error
					)}`,
					"debug"
				);
			});
	}

	handleSelectLine(index, _id, selected) {
		this.setState(({ orders }) => {
			orders = [...orders];
			orders[index] = { ...orders[index], selected };
			return { orders };
		});
	}

	handleStatusChange(value) {
		this.setState((state) => ({ selected_status: value }));
	}

	handleReturnOrder(_id) {
		this.props.returnOrder(_id).then((res) => {
			this.applyFilters(this.state.filters);
		});
	}

	rejectOrder(_id) {
		let message = window.prompt("SVP Ajouter un commentaire");
		if (message != null) {
			this.props.rejectOrder(_id, message).then((res) => {
				this.applyFilters(this.state.filters);
			});
		}
	}

	cancelOrder(_id) {
		if (window.confirm("Etes vous sur?")) {
			this.props.cancelOrder(_id).then((res) => {
				this.applyFilters(this.state.filters);
			});
		}
	}

	handleSelectPage(selected) {
		this.setState(({ orders }) => {
			orders = [...orders].map((o) => ({ ...o, selected }));
			return { orders };
		});
	}

	patchGroup(status = null) {
		status = status ? status : this.state.selected_status;

		let orders = this.state.orders
			.filter(({ selected }) => selected)
			.map(({ _id }) => ({ _id, status }));

		this.props.patchOrdersGroup(orders).then(() => {
			this.applyFilters(this.state.filters); // fetch a new list with the applied filters
		});
	}

	render() {
		let { pagination, orders } = this.state;

		let table = orderListTable({
			status: config.status,
			clients: arrayToObject(this.props.clients, "_id"),
			onSelectLine: this.handleSelectLine,
			onSelectPage: this.handleSelectPage,
			isPageSelected:
				this.state.orders.filter(({ selected }) => selected)
					.length == this.state.orders.length,
			onReject: this.rejectOrder,
			onCancel: this.cancelOrder,
			onReturn: this.handleReturnOrder,
		});

		pagination = {
			...pagination,
			current: pagination && pagination.page ? pagination.page : 1,
		};

		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={this.actions}
					title="Historique des commandes"
				/>
				<ReduxAlert />

				<OrdersFilters
					onSave={this.applyFilters}
					filters={this.state.filters}
					// disabled={this.props.loading}

					clients={this.props.clients}
				/>

				<div className="float-left" id="group-actions">
					<PatchRow
						status={config.status}
						selectedStatus={this.state.selected_status}
						onStatusChange={this.handleStatusChange}
						disabled={
							orders.filter(
								({ selected }) => selected
							).length < 1
						}
						onPatch={this.patchGroup}
					/>
				</div>
				<br />
				<br />
				<ReduxProtectedGenericTable
					cols={table.cols}
					data={orders}
				/>

				<div id="bottom-page">
					<div className="float-left" id="group-actions">
						<PatchRow
							status={config.status}
							selectedStatus={
								this.state.selected_status
							}
							onStatusChange={
								this.handleStatusChange
							}
							disabled={
								orders.filter(
									({ selected }) => selected
								).length < 1
							}
							onPatch={this.patchGroup}
						/>
					</div>

					<div className="float-right">
						<Pagination
							onClick={this.fetchPage}
							{...pagination}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => ({
	clients: state.clients.clients.filter(
		(c) => ["client", "distributor"].indexOf(c.roles) > -1
	),
});
const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (filters = {}, page = 1) =>
		ordersCreators.fetch(filters, page)(dispatch),
	patchOrdersGroup: (orders) => ordersCreators.patchGroup(orders)(dispatch),
	rejectOrder: (_id, comment) =>
		ordersCreators.patch({ _id, status: "REJECTED", comment })(
			dispatch
		),
	cancelOrder: (_id) =>
		ordersCreators.patch({ _id, status: "CANCELED" })(dispatch),
	returnOrder: (_id) =>
		ordersCreators.patch({ _id, status: "RETURN" })(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListPage);
