import React from "react";

const PageTop = ({ title, actions }) => {
	return (
		<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 my-3 border-bottom">
			<h1 className="h2">{title}</h1>
			<div className="btn-toolbar mb-2 mb-md-0">
				{actions.map((btn) => (
					<button
						key={btn.text}
						disabled={btn.disabled}
						type="button"
						className={`btn ml-2 btn-sm ${
							btn.color
								? btn.color
								: "btn-outline-secondary"
						}`}
						onClick={btn.onClick}
					>
						{btn.text}
					</button>
				))}
			</div>
		</div>
	);
};

export default PageTop;
